html {
  height: 100%;
}
body {
  height: 100%;
  overflow-x: hidden;
}
app {
  display: block;
  height: 100%;
}
.site-container {
  height: 100%;

  > * {
    display: block;
  }
  > router-outlet {
    display: none;
  }
  .main-content {
    height: calc(100% - #{$module * 2});
    -webkit-overflow-scrolling: touch;
    background-color: white;
    .main-content-wrap {
      display: table;
      height: 100%;
      padding: 22px;
      table-layout: fixed;
      width: 100%;
      background-color: white;
    }
  }
}
